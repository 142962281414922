@import '../../style/themes/index';
@import '../../style/mixins/index';

@avatar-prefix-cls: ~'@{ant-prefix}-avatar';

.@{avatar-prefix-cls} {
  .reset-component;

  position: relative;
  display: inline-block;
  overflow: hidden;
  color: @avatar-color;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: @avatar-bg;

  &-image {
    background: transparent;
  }

  .avatar-size(@avatar-size-base, @avatar-font-size-base);

  &-lg {
    .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
  }

  &-sm {
    .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
  }

  &-square {
    border-radius: @avatar-border-radius;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-size(@size, @font-size) {
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 50%;

  &-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }

  &.@{avatar-prefix-cls}-icon {
    font-size: @font-size;

    > .@{iconfont-css-prefix} {
      margin: 0;
    }
  }
}

@primary-color: #2F54EB;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;